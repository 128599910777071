import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '../../../constants/api-constants';
import { HttpCoreService } from '../http/http-core.service';
import { TransformationModelDownload } from 'src/app/shared/models/transformation-model-download';
import { TransformationModelUpload, toFormData } from 'src/app/shared/models/transformation-model-upload';
import { TransformationModelDelete } from 'src/app/shared/models/transformation-model-delete';
import {
  ArtifactDeleteModel,
  ArtifactModelDownload, ArtifactModelUpload, ArtifactService
} from '@shared-library/artifact-service';

@Injectable({
  providedIn: 'root'
})

export class TemplateManagementService implements ArtifactService {

  constructor(private httpCore: HttpCoreService) { }

  getArtifactDetails(namespace: string): Observable<any> {
    return this.httpCore.get(
      API_ENDPOINT.getTemplateDetailsEndpoint(namespace)
    );
  }
  postDeleteArtifact(body: ArtifactDeleteModel): Observable<any> {
    var request : TransformationModelDelete = {
      Namespace: body.Namespace,
      TemplateName: body.Name
    }
    return this.httpCore.post(API_ENDPOINT.DELETE_TEMPLATE_POST, request);
  }
  PostArtifactUpload(body: ArtifactModelUpload): Observable<any> {
    var request : TransformationModelUpload = {
      Namespace  : body.Namespace,
      TemplateName : body.Name,
      Template: body.Content
    }
    const formData = toFormData(request);
    const headers = { 'enctype': 'multipart/form-data' };
    return this.httpCore.post(API_ENDPOINT.UPLOAD_TEMPLATE_POST, formData);
  }
  PostArtifactDownload(body: ArtifactModelDownload): Observable<any> {
    var request : TransformationModelDownload = {
      Namespace: body.Namespace,
      TemplateName: body.Name
    }
    return this.httpCore.post(API_ENDPOINT.DOWNLOAD_TEMPLATE_POST, request);
  }
}
