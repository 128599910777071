import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ElementRef, ViewChild } from '@angular/core';
import { TemplateManagementService } from 'src/app/shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';
import { TemplateExecutionService } from 'src/app/shared/services/ComponentServices/execution-service/template-execution.service';
import { ArtifactModelDownload, ArtifactModelUpload } from '@shared-library/artifact-service';


@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.css']
})
export class TemplateEditorComponent implements OnInit {
  namespace: string = '';
  templateName: string = '';
  inputFileUploaded: boolean = false;
  outputFileUploaded: boolean = false;
  aiChangesMade: boolean = false;
  uploadSucess: boolean = false;
  aiChangesLoading: boolean = false
  aiErrorResponse: boolean = false;
  prompt: string = "";
  templateInputData: string = JSON.stringify({ input: "Input data" });
  templateOutputData: string = JSON.stringify({ output: "Output data" });
  executed_output: string = "";
  selectedInputFileName: string = '';
  selectedOutputFileName: string = '';
  showAiTemplate: boolean = false;
  sameAsExpectedOutput: boolean = false;
  private templateChangeTimeout: any;
  constructor(
    private router: Router,
    private templateManagerSercice: TemplateManagementService,
    private templateexecuteservice: TemplateExecutionService) { }

  @ViewChild('acceptDiscardPopup') acceptDiscardPopup!: ElementRef;
  @ViewChild('areYouSureSubmit') areYouSureSubmit!: ElementRef;


  // METHOD TO OPEN LOCAL FILE MANAGER
  openFileManager(type: 'input' | 'output') {
    const fileUploadInput = document.getElementById(type === 'input' ? 'fileUploadInput' : 'fileUploadOutput') as HTMLInputElement;
    if (fileUploadInput) {
      fileUploadInput.click();
    }
  }

  // INPUT AND OUTPUT JSON FILE HANDLING LOGIC
  onInputFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      const file = input.files[0]; // accesss the file
      const reader = new FileReader();



      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.templateInputData = e.target?.result as string;  // store file data 
        console.log(this.templateInputData);
      };
      reader.readAsText(file);
      this.selectedInputFileName = file.name;
    }
  }


  onOutputFileSelected(event: Event): void {
    const output = event.target as HTMLInputElement;
    if (output.files && output.files.length) {
      const file = output.files[0];
      const reader = new FileReader();

      reader.onload = (e: ProgressEvent<FileReader>) => {
        this.templateOutputData = e.target?.result as string;
        console.log(this.templateOutputData);
      };

      reader.readAsText(file);
      this.selectedOutputFileName = file.name;
    }
  }



  handleAiResponse(event: string): void {
    if (event === "I am Taz and can't do that.") {
      this.aiErrorResponse = true;
    } else {
      this.modifiedModel.code = event;
      this.aiChangesMade = true;
    }
  }


  public jsonEditorOptions = {
    theme: 'vs-light',
    language: 'json',
    automaticLayout: true
  };
  jsonCode: string = `{
    "name": "John",
    "age": 30
  }`;
  JSONeditorStyle = {
    height: '100%', // Initial height
    width: '100%',    // Initial width
  };
  ReizeBoxeditorStyle = {
    width: '300px',
    height: '150px'
  };
  /// Functions to remove Input and Output Data 

  removeInputFile(): void {
    this.templateInputData = '';
    this.selectedInputFileName = '';
    this.inputFileUploaded = false;
  }

  removeOutputFile(): void {
    this.templateOutputData = '';
    this.selectedOutputFileName = '';
    this.outputFileUploaded = false;

  }

  toggleAiTemplate() {
    this.showAiTemplate = !this.showAiTemplate;
  }

  closeAI(event: boolean) {
    this.showAiTemplate = event;
  }

  loadAiChanges(event: boolean) {
    this.aiChangesLoading = event;
  }

  // Template Editor and Input/Output Editor Settings
  public inputOutputEditorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
  };
  templateEditorOptions = {
    theme: 'vs-dark',
    language: 'liquid',
    automaticLayout: true,
  };
  executeEditorOptions = {
    theme: 'vs-dark',
    language: 'json',
    automaticLayout: true,
    readOnly: true
  };


  templateCode: string = `Upload Input and Output JSON files to get started!`;


  // Diff Editor Settings

  originalModel = {
    code: this.templateCode,
    language: 'liquid'
  };

  modifiedModel = {
    code: '',
    language: 'liquid'
  };

  diffEditorOptions = {
    theme: 'vs-dark',
    automaticLayout: true,
    readOnly: true,
    renderSideBySide: false // ASSIGN TO VARIABLE 
  };


  acceptChanges(): void {
    this.templateCode = this.modifiedModel.code;
    this.originalModel.code = this.modifiedModel.code;
    this.aiChangesMade = false;
    this.acceptDiscardPopup.nativeElement.hide();

  }

  declineChanges(): void {
    this.aiChangesMade = false;
    this.acceptDiscardPopup.nativeElement.hide();
  }


  onTemplateChange(): void {
    if (this.templateChangeTimeout) {
      clearTimeout(this.templateChangeTimeout);
    }
    this.templateChangeTimeout = setTimeout(() => {
      this.executeTemplate();
      setTimeout(() => {
        this.checkOutputEquality();
      }, 500); // Delay for compareExecution
    }, 1000);
  }

  checkOutputEquality(): boolean {
    // Remove all whitespace from both strings
    const str1 = this.executed_output;
    const str2 = this.templateOutputData;
    const cleanedExecution = str1.replace(/\s+/g, '');
    const cleanedOutput = str2.replace(/\s+/g, '');

    // Compare the cleaned strings
    if (cleanedExecution === cleanedOutput) {
      return true;
    } else {
      return false;
    }
  }

  async executeTemplate(): Promise<void> {
    var request = {
      Template: this.templateCode,
      Input: this.templateInputData
    }
    this.originalModel.code = this.templateCode;
    this.executed_output = await this.templateexecuteservice.executeTemplate(request);
  }

  confirmSubmit(): void {
    this.areYouSureSubmit.nativeElement.show();
  }
  closeSubmit(): void {
    this.areYouSureSubmit.nativeElement.hide();
  }

  submitTemplate(): void {
    if (!this.namespace || !this.templateName) {
      console.log('No Namespace or Templatename')
      return;
    }
    var templateToUploads: ArtifactModelUpload = {
      Namespace: this.namespace,
      Name: this.templateName + (!this.templateName.endsWith('.liquid') ? '.liquid' : ''),
      Content: new Blob([this.templateCode], { type: 'text/plain' })
    }
    this.templateManagerSercice.PostArtifactUpload(templateToUploads).subscribe({
      next: (response: any) => {
        console.log('Response:', response);
        this.uploadSucess = true;
        this.areYouSureSubmit.nativeElement.hide();
      },
      error: (error) => {
        console.error('Error:', error);
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          console.error('Error Event:', error.error.message);
        } else {
          // Server-side error
          console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
      },
    });
  }

  sendToTemplateManager(): void {
    this.router.navigate(['./templatemanager']);
  }

  ngOnInit(): void {
    window.onbeforeunload = () => {
      history.replaceState({}, '');
    };

    const routerState = history.state;
    this.namespace = routerState.namespace;
    this.templateName = routerState.artifactName;
    this.templateInputData = routerState?.artifactInputData ?? this.templateInputData;
    this.selectedInputFileName = routerState?.selectedInputFileName?? this.selectedInputFileName;
    this.templateOutputData = routerState?.artifactOutputData ?? this.templateOutputData;
    this.selectedOutputFileName = routerState?.selectedOutputFileName?? this.selectedOutputFileName;

    var templateToedit: ArtifactModelDownload = {
      Namespace: this.namespace,
      Name: this.templateName
    }
    this.templateManagerSercice.PostArtifactDownload(templateToedit).subscribe({
      next: (response: any) => {
        this.templateCode = response.content;
      },
      error: (error) => {
        console.error('Error:', error);
        if (error.error instanceof ErrorEvent) {
          // Client-side error
          console.error('Error Event:', error.error.message);
        } else {
          // Server-side error
          console.error(`Server-side error: ${error.status} - ${error.message}`);
        }
      },
    });

    (window as any).monaco.languages.register({ id: 'liquid' });

    (window as any).monaco.languages.setMonarchTokensProvider('liquid', {
      tokenizer: {
        root: [
          [/\{\{/, 'delimiter'],
          [/\}\}/, 'delimiter'],
          [/\{\%/, 'delimiter'],
          [/\%\}/, 'delimiter'],
          [/[a-z_$][\w$]*/, 'identifier'],
          [/[ \t\r\n]+/, 'whitespace'],
          [/".*?"/, 'string'],
          [/'(\\'|.)*?'/, 'string'],
        ]
      }
    });
  }

}

