import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { scope } from 'src/scope';
import { AppRoutingModule } from './app-routing.module';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { TemplateManagerLayoutComponent } from './components/template-manager-layout/template-manager-layout.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ResizableModule } from 'angular-resizable-element';
import { TemplateAiComponent } from './components/template-ai/template-ai.component';
import { ArtifactManagerModule } from '@shared-library/artifact-manager';
import { ARTIFACT_SERVICE } from '@shared-library/artifact-service';
import { TemplateManagementService } from './shared/services/ComponentServices/TemplateManagement-api/template-management-api.service';



scope.registerComponent();
@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    TemplateManagerLayoutComponent,
    TemplateEditorComponent,
    TemplateAiComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ResizableModule,
    MonacoEditorModule.forRoot(),
    ArtifactManagerModule
  ],
  providers: [
    { provide: ARTIFACT_SERVICE, useClass: TemplateManagementService }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
