import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template-manager-layout',
  templateUrl: './template-manager-layout.component.html',
  styleUrls: ['./template-manager-layout.component.css']
})

export class TemplateManagerLayoutComponent {
  artifact = 'Template';
  groupName = 'SupplierCollab';
  availableNamespaces = ['SupplierCollab']
  sidebarHeadingDesc = "Provide the details for the new template as required. Click Submit to proceed to the editor page for your new template.";

  constructor(private router: Router){}
  
  sendToEditor(data: any) {
    this.router.navigate(['/templateeditor'], { state: data });
  }
}
