import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TemplateManagerLayoutComponent } from './components/template-manager-layout/template-manager-layout.component';
import { TemplateEditorComponent } from './components/template-editor/template-editor.component';


const routes: Routes = [
  {
    path: '',
    component: TemplateManagerLayoutComponent
  },
  {
    path:'templatemanager',
    component: TemplateManagerLayoutComponent
  },
  {
    path: 'templateeditor',
    component: TemplateEditorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
